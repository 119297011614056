.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    font-size: 0.5em;
  }

}

@media print {
  .page-break {
    margin-top: 0;
    display: block;
    page-break-before: always;
  }
}

@page {
  size: auto;
  margin: 0;
  margin-top: 2mm;
}
